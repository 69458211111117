:root {
    --height_delivery_collection_button_1400: 40px;
    --border_radius_button_1400: 4px;
    --content_width: 92%;
    --button_font_size_1400: 17px;
    --button_font_size_768: 14px;
    --height_delivery_collection_1400: 40px;
    --height_header_container_1400: 50px;
    --h3_1400: 21px;
    --h4_1400: 19px;
    --h5_1400: 16px;
    --h6_1400: 14px;
    --h6_560: 13px;
    --primary_color: #ff1e00;
    --primary_light: #ff6237;
    --primary_dark: #c30000;
    --product_title_color: #1d232c;
    --product_description_color: #828585;
    --border_radius_button: 5px;
    --height_delivery_collection_button: 55px;
    --section_background_color: #f0f0f0;
    --section_background_color_dark: #bebebe;
    --light_border_color: #eee;
}

@media screen and (max-width: 1400px) {
    .profile-create-acount h4 {
        color: var(--profile_title_color);
        text-align: center;
        margin: 0;
        margin-bottom: 8px;
        font-size: var(--h4_1400);
    }
    .profile-container-inputs {
        width: 100%;
        margin: 10px 0;
    }
    .profile_input {
        height: var(--height_delivery_collection_button_1400);
    }
    .profile_input_label {
        font-size: 16px;
        top: -1;
    }
    .profile-create-account-button {
        height: var(--height_delivery_collection_button_1400);
        margin: 12px 0;
        border-radius: var(--border_radius_button_1400);
        font-size: var(--button_font_size_1400);
    }
    .buttons_container button {
        width: 48%;
        font-size: var(--button_font_size_1400);
    }
    .profile-sidebar_item_container {
        height: 70px;
        padding: 0 4%;
    }
    .profile-sidebar_item_container h4 {
        font-size: var(--h4_1400);
    }
    .profile-sidebar_item_container i {
        font-size: 22px;
    }
    .change_pass_content_container {
        border-radius: 4px;
        padding: 25px 0;
    }
    .change_pass_content_container h6 {
        font-size: var(--h6_1400);
    }
    .change_password_textfield_container {
        margin-bottom: 40px;
    }
    .change_password_button_container {
        height: var(--height_delivery_collection_button_1400);
    }
    .change_password_button_container button {
        border-radius: var(--border_radius_button_1400);
        font-size: var(--button_font_size_1400);
    }
    .addresses_list_main_container h4 {
        font-size: var(--h4_1400);
        margin-bottom: 25px;
    }
    .address_list_item_container {
        padding: 2%;
        margin-bottom: 14px;
        border-radius: var(--border_radius_button_1400);
    }
    #create_shop_popup_content_container_id {
        width: 45%;
    }
    .addresses_list_main_container h5 {
        font-size: var(--h5_1400);
        margin-bottom: 20px;
    }
    .address_list_button_container {
        padding-top: 20px;
    }
    .address_list_button_container i {
        font-size: 20px;
        color: var(--primary_color);
        margin-right: 10px;
    }
    .add_address_button_container {
        height: var(--height_delivery_collection_button_1400);
    }
    .add_address_button_container button {
        border-radius: var(--border_radius_button_1400);
        font-size: var(--button_font_size_1400);
    }
    .product-submenu-popup-container-image-and-close-icon {
        height: 60%;
    }
    .product-submenu-popup-container-size h5 {
        font-size: var(--h5_1400);
    }
    .product-submenu-popup-container-size h6 {
        font-size: var(--h6_1400);
    }
    .product_submenu_popup_optiones h6 {
        font-size: var(--h6_1400);
    }
    #option_title {
        margin-bottom: 9px;
        margin-top: 3px;
        font-size: 13px;
    }
    .product_submenu_popup_container_option_crust {
        margin-bottom: 8px;
    }
    .product_submenu_popup_container_option_crust h6 {
        font-size: var(--h6_1400);
    }
    .option_title_price_container h6 {
        font-size: var(--h6_1400);
    }
    .min_max_container {
        width: 55%;
    }
    .product-submenu-popup-container-title-and-min-max-select h6 {
        font-size: var(--h6_1400);
    }
    .product-submenu-popup-submenu-item h4 {
        font-size: var(--h4_1400);
    }
    .product-submenu-popup-submenu-item h3 {
        font-size: var(--h3_1400);
    }
    .item_number_selector i {
        font-size: 19px;
    }
    .item_number_selector h5 {
        font-size: var(--h5_1400);
    }
    .item_price_container h6 {
        font-size: var(--h6_1400);
    }
    .product_detail_minus_plus_product {
        margin-top: 13px;
    }
    .product-submenu-popup-contsiner-icons h3 {
        font-size: var(--h3_1400);
    }
    .product-submenu-popup-contsiner-footter i {
        font-size: 23px;
    }
    .product-submenu-popup-contsiner-footter button {
        margin: 17px 0;
        font-size: var(--button_font_size_1400);
        height: var(--height_delivery_collection_button_1400);
    }
    #basket_container {
        width: 35%;
    }
    .basket_delivery_collection_container h5 {
        font-size: var(--h5_1400);
    }
    .basket_delivery_collection_container h6 {
        font-size: var(--h6_1400);
    }
    .basket_delivery_collection_container p {
        font-size: 11px;
    }
    .basket_product_title_container i {
        font-size: 18px;
    }
    .basket_product_container h6 {
        font-size: var(--h6_1400);
    }
    .basket_sublist_container h6 {
        font-size: var(--h6_1400);
    }
    .basket_footer_container h5 {
        font-size: var(--h5_1400);
    }
    .basket_footer_container h4 {
        font-size: var(--h4_1400);
    }
    .menu-header-div {
        height: var(--height_header_container_1400);
    }
    .menu-container-logo {
        height: 40px;
    }
    .menu-container-logo img {
        width: 34px;
        height: 34px;
        border-radius: 30px;
    }
    .menu-container-h6-h3 h6 {
        font-size: var(--h6_1400);
    }
    #main_header_shop_address {
        font-size: 9px;
    }
    .basket_icon_container {
        min-width: 75px;
        padding: 0 6px;
    }
    .basket_icon_container h6 {
        font-size: var(--h6_1400);
    }
    .menu-get-list-details {
        margin-right: 15px;
        padding: 5px;
        height: 200px;
    }
    .menu-get-list-details h6 {
        font-size: var(--h6_1400);
        line-height: 18px;
        max-height: 20px;
    }
    .products-container-texts h5,
    .menu-get-list-details h5 {
        font-size: var(--h5_1400);
    }
    .menu-get-list-details img {
        height: 100px;
        margin-bottom: 6px;
    }
    .menu-container-search-and-dellivery-collection {
        height: var(--height_delivery_collection_1400);
        margin-top: 12px;
    }
    .menu-container-image {
        height: 260px;
    }
    .categories_title_container {
        height: 43px;
        padding: 9px 0px 0px 6px;
    }
    .categories-container-categories-list {
        height: 35px;
        padding: 0 8px;
        margin-bottom: 12px;
    }
    .categories-container-categories-list h6 {
        font-size: var(--h6_1400);
    }
    .products-container-any-product {
        margin-bottom: 8px;
        border-radius: 6px;
        padding: 6px;
        min-height: 100px;
        max-height: 165px;
    }
    .checkout_input_container h6 {
        font-size: var(--h6_1400);
    }
    .checkout_input_container input {
        height: var(--height_delivery_collection_1400);
        padding: 12px;
    }
    .checkout_address_container {
        min-height: var(--height_delivery_collection_1400);
        margin-bottom: 25px;
    }
    .checkout_mobile_number_container {
        margin-bottom: 10px;
    }
    #mobile_number_error_container {
        height: 15px;
    }
    #mobile_number_error_container p {
        font-size: 12px;
    }
    .checkout_delivery_time_container {
        margin-bottom: 25px;
        height: var(--height_delivery_collection_1400);
    }
    .checkout_special_note_container {
        margin-bottom: 25px;
    }
    .checkout_special_note_container textarea {
        padding: 8px 12px;
    }
}

@media screen and (max-width: 992px) {
    .menu-container-tags h6 {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .discount_notice_container {
        max-width: 85%;
        padding: 10px;
        border-radius: 4px;
    }
    .discount_notice_container h1 {
        display: none;
    }
    .discount_notice_container h5 {
        margin: 0;
        display: block;
    }
    .six_step_mobile_container {
        display: block;
    }
    .review_container {
        height: calc(100vh - 50px);
        margin-top: 50px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* background-color: var(--background_color_main); */
        background-color: #fff;
    }
    .order_details_main_container {
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 36656566;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .order_details_container {
        height: 90%;
        width: 90%;
        background-color: var(--section_background_color);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: var(--border_radius_button);
    }
    .myorders_main_container {
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        position: relative;
    }
    .myorders_content_container {
        width: 100%;
        height: 75px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        margin-bottom: 10px;
        background-color: white;
        border: none;
        border-radius: 5px;
    }
    .myorders_mobile_item_container {
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--light_border_color);
        width: 100%;
        height: 100%;
        padding: 10px;
        margin-right: 10px;
        border-radius: 5px;
    }
    .myorders_mobile_item_container div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .myorders_mobile_item_container p {
        margin: 0;
        font-size: 13px;
    }
    .myorders_mobile_button_container {
        display: flex;
        height: 100%;
    }
    .myorders_mobile_button_container button {
        height: 100%;
    }
    .checkout_special_note_container textarea {
        font-size: 13px;
    }
    .checkout_address_text_container p {
        font-size: 13px;
    }
    .address_input_container h6 {
        font-size: 13px;
    }
    .address_list_address_container p {
        font-size: 12px;
    }
    .drawer {
        display: block;
        position: fixed;
        z-index: 65656556556565656;
        right: 0;
        bottom: 0;
        top: 0;
    }
    .successful_content_container {
        height: 95%;
        width: 85%;
        display: flex;
        justify-content: space-between;
    }
    .six_step_container {
        display: none;
    }
    .mobile_time_gap_container {
        position: absolute;
        width: 100%;
        display: flex;
        left: 0;
        right: 0;
        bottom: 10px;
        justify-content: center;
        align-items: center;
    }
    .footer_copy_right_container {
        height: 40px;
        width: 100%;
        background-color: var(--primary_color);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer_copy_right_container h5 {
        display: none;
    }
    .footer_copy_right_container p {
        display: block;
        margin: 0;
        color: var(--color_white);
        font-size: 10px;
    }
    .forget_password_content_container h3 {
        font-weight: normal;
    }
    .forget_password_content_container h4 {
        font-size: 16px;
    }
    .forget_password_content_container h6 {
        margin-bottom: 15px;
        font-size: 13px;
    }
    .new_password_input_container h6 {
        margin-bottom: 8px;
    }
    .new_password_input_container input {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        border: 1px solid var(--light_border_color);
        outline: none;
        padding: 0 10px;
        margin-bottom: 5px;
        font-size: 14px;
    }
    .loading_product {
        display: none;
    }
    .loading_all_product {
        display: block;
    }
    .footer_container {
        display: none;
    }
    .footer_container_mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 1px solid var(--light_border_color);
        padding-top: 20px;
        background-color: var(--section_background_color);
    }
    .footer_container_mobile section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }
    .footer_container_mobile h3 {
        margin-bottom: 10px;
    }
    .footer_container_mobile img {
        width: 35%;
        object-fit: cover;
        cursor: pointer;
    }
    .footer_container_mobile h6 {
        color: var(--product_description_color)
    }
    .footer_container_mobile i {
        font-size: 35px;
    }
    .maintenance_mode_message_content_container h4 {
        margin: 0;
        display: none;
    }
    .maintenance_mode_message_content_container h5 {
        margin: 0;
        display: block;
    }
    .maintenance_mode_message_content_container {
        min-width: 90%;
        max-width: 90%;
        width: 90%;
        padding: 20px 40px;
    }
    .login_with_google_container {
        height: var(--height_delivery_collection_button_1400);
    }
    .mobile_product_list_category_header_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var();
        margin-bottom: 11px;
        border-radius: 8px;
        padding: 15px 8px;
        border: 1px solid var(--light_border_color);
        background-color: var(--section_background_color);
    }
    .mobile_product_list_category_header_container h6 {
        color: var(--product_description_color);
    }
    .mobile_category {
        display: block;
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        right: 0;
        left: 0;
        z-index: 123;
        /* height: 55px; */
        background-color: white;
        list-style-type: none;
        align-items: center;
        padding: 0 0 0 4%;
        border-bottom: 1px solid var(--light_border_color);
        border-top: 1px solid var(--light_border_color);
    }
    /* .mobile_category ul {
        display: flex;
        overflow-x: scroll;
        list-style-type: none;
        align-items: center;
        background-color: white;
        height: 55px;
        padding: 0 4%;
        border-bottom: 1px solid var(--light_border_color);
        border-top: 1px solid var(--light_border_color);
    } */
    .go_to_payment_button_container_mobile {
        width: 100%;
        padding: 0 5%;
        height: var(--height_delivery_collection_button_1400);
        margin-bottom: 5%;
        display: block;
    }
    .go_to_payment_button_container {
        display: none;
        /* height: var(--height_delivery_collection_button_1400); */
    }
    .go_to_payment_button_container_mobile button {
        width: 100%;
        height: 100%;
        border-radius: var(--border_radius_button);
        background-color: var(--primary_color);
        color: var(--button_text_color);
        border-color: var(--primary_color);
        text-transform: none;
        font-size: var(--button_font_size_768);
    }
    .add_address_button_container {
        display: none;
    }
    .mobile_add_address_button_container {
        width: 100%;
        padding: 0 5%;
        height: var(--height_delivery_collection_button_1400);
        margin-bottom: 5%;
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
    }
    .mobile_add_address_button_container button {
        width: 100%;
        height: 100%;
        border-radius: var(--border_radius_button);
        background-color: var(--primary_color);
        color: var(--button_text_color);
        border-color: var(--primary_color);
        font-size: var(--button_font_size);
        text-transform: none;
    }
    .footer_successful_page {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 0.5px solid var(--dark_border_color);
        margin: auto;
        background-color: var(--section_background_color);
        border-bottom-left-radius: var(--border_radius_button);
        border-bottom-right-radius: var(--border_radius_button);
    }
    .successfull_basket {
        height: 80%;
        /* margin: auto; */
        width: 90%;
        border-radius: var(--border_radius_button);
    }
    #successfull_basket {
        height: 70%;
    }
    .addresses_list_main_container {
        padding-bottom: 70px;
    }
    .mobile_address_list_button_container {
        align-self: flex-start;
        display: flex;
        align-items: center;
        padding-top: 25px;
        position: fixed;
        bottom: 0;
        right: 5px;
        left: 5px;
        padding: 20px 3%;
        background-color: white;
    }
    .mobile_address_list_button_container i {
        font-size: 25px;
        color: var(--primary_color);
        margin-right: 10px;
    }
    .mobile_address_list_button_container h5 {
        margin: 0;
    }
    /* .address_list_button_container {
        display: none;
    } */
    .change_password_inputs_container h6 {
        margin-bottom: 30px;
    }
    .change_password_textfield_container {
        margin-bottom: 30px;
    }
    .change_pass_content_container {
        width: 80%;
        height: 60%;
        margin-top: -100px;
    }
    .buttons_container button {
        font-size: var(--button_font_size_768);
    }
    .profile_main_container {
        padding-left: 0;
        padding-top: 0;
        background-color: var(--color_white);
    }
    .profile-sidebar_container {
        display: none;
    }
    .menu-container-logo {
        width: 65%;
        height: 45px;
        display: flex;
        cursor: pointer;
        align-items: center;
    }
    #user_container {
        min-width: 85px;
        height: 70%;
        border: 1px solid #ccc;
        margin-left: 0;
        border-radius: 3px;
    }
    #main_header_menu_text_container {
        margin-left: 0;
    }
    .menu-header-div2 {
        display: none;
    }
    .mobile_header_view {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 5%;
    }
    #mobile_paymethod_checkbox {
        font-size: 13px;
    }
    .review_checkbox_container {
        margin: 0;
        width: 49%;
        padding: 3px 5px;
        border: none;
        background-color: var(--light_border_color);
    }
    .mobile_pay_method_container {
        display: flex;
        /* margin: auto; */
        /* margin-bottom: 10px; */
        /* margin-top: 30px; */
        width: 82%;
        margin: auto;
        margin-top: 11px;
        margin-bottom: 5px;
        justify-content: space-between;
        border: 1px solid var(--dark_border_color);
        border-radius: var(--border_radius_button);
        background-color: var(--light_border_color);
    }
    .basket_footer_container {
        width: 90%;
        margin-top: 10px;
    }
    .basket_footer_container h6 {
        margin: 0;
    }
    .review_footer_container {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 0.5px solid var(--dark_border_color);
        margin: auto;
    }
    .review_footer_container button {
        font-size: var(--button_font_size);
        height: var(--height_delivery_collection_mobile);
        width: 90%;
        border: none;
        border-radius: 3px;
        color: white;
        cursor: pointer;
        margin: auto;
        margin-bottom: 10px;
        /* margin: 10px 0; */
        font-weight: bold;
        text-transform: none;
    }
    .review_basket_list_container {
        width: 100%;
        padding: 10px 5%;
    }
    .review_content_container {
        width: 100%;
        flex-direction: column;
        height: 100%;
        justify-content: unset;
    }
    .review_basket {
        width: 90%;
        margin: 15px 5%;
        height: 80%;
    }
    .review_payment_method {
        display: none;
    }
    .address_list_item_container {
        width: 95%;
        padding: 2%;
    }
    #address_header_title {
        display: none;
    }
    .basket_header_container {
        /* position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 65656566; */
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
        padding: 10px 4%;
        background-color: var(--light_border_color);
        justify-content: space-between;
        height: 65px;
    }
    .basket_header_container h6 {
        margin: 0;
    }
    #create_shop_popup_content_container_id {
        width: 100%;
    }
    .checkout_input_container {
        margin-top: -100px;
    }
    .checkout_input_container h6 {
        font-size: var(--h6_560);
    }
    .checkout_input_container input {
        font-size: var(--h6_560);
    }
    .profile-container-secifications {
        width: 90%;
        margin-top: -150px;
    }
    .mobile_product-submenu-popup-contsiner-footter {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 0.5px solid var(--dark_border_color);
        z-index: 656565656565;
        background-color: white;
    }
    .mobile_product-submenu-popup-contsiner-footter button {
        font-size: var(--button_font_size_768);
        margin: 25px 0 15px 0;
        height: 41.25px;
        width: 100%;
        border: none;
        border-radius: 3px;
        color: white;
        cursor: pointer;
        font-weight: bold;
        text-transform: none;
    }
    .mobile_product-submenu-popup-contsiner-footter i {
        color: var(--primary_color);
        font-size: 25px;
    }
    .product-submenu-popup-contsiner-footter {
        display: none;
    }
    .profile-container {
        background-color: white;
    }
    .profile-create-acount {
        height: 100%;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        border: none;
    }
    #mobile_view_basket_item_count {
        display: flex;
        height: 27px;
        width: 27px;
        background-color: var(--primary_dark);
        border-radius: 4px;
        justify-content: center;
        align-items: center;
    }
    .button_view_basket_mobile {
        display: flex;
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
        z-index: 1234567899;
        height: var(--height_delivery_collection_button);
        background-color: white;
    }
    .button_view_basket_mobile button {
        width: 92%;
        height: 75%;
        margin: auto;
        border: none;
        border-radius: 3px;
        background-color: var(--primary_color);
        color: white;
        display: flex;
        justify-content: space-between;
        padding: 0 5%;
        text-transform: none;
        font-weight: bold;
    }
    .product_detail_minus_plus_product {
        margin-top: 20px;
    }
    .product-submenu-popup-contsiner-icons {
        width: 32%;
    }
    .product-submenu-popup-container-submenus-informations {
        margin: auto;
        padding-top: 6px;
    }
    #subitem_style {
        font-size: 14px;
    }
    .submenu_title_container {
        width: 50%;
    }
    .close_in_mobile_container {
        display: inline;
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #ccc;
        border-radius: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 15px;
        right: 15px;
        z-index: 12345678;
    }
    .close_in_mobile_container i {
        color: var(--primary_color);
        font-size: 25px;
    }
    .create_shop_popup_content_container {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    .addreses_list_content_container2 {
        display: none;
    }
    .addresses_list_mobile_content_container {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background-color: yellow;
        z-index: 123;
    }
    .menu-container-tags h6 {
        font-size: 12px;
    }
    .search_box_container {
        width: 100%;
        height: 100%;
        position: relative;
        padding-right: 0;
    }
    .product_list_mobile_view {
        display: block;
    }
    .basket_delivery_collection_container {
        display: none;
    }
    .category_sticky_container {
        display: none;
    }
    .basket_sticky {
        display: none;
    }
    .product_list_half_container {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    /* #mui_input {
        top: -5;
        font-size: 15px;
    } */
    .item_price_container h6 {
        font-size: var(--h6_560)
    }
    #subitem_style {
        font-size: 12px;
    }
    .product_option_container {
        width: 98%;
    }
    .product-submenu-popup-container-image-and-close-icon {
        height: 40%;
    }
    .product-submenu-popup-container-image-and-close-icon img {
        border-radius: 0;
    }
    .products-container-image {
        padding: 0;
    }
    .products-container-texts {
        padding: 4px 10px 4px 7px;
    }
    .products-container-texts p {
        line-height: 18px;
        max-height: 60px;
        font-size: 12px;
    }
    #product_price {
        font-size: 15px;
    }
    .products-container-texts h6,
    .menu-get-list-details h6 {
        font-size: var(--h6_560)
    }
    .menu-container-search-and-dellivery-collection {
        width: var(--content_width);
        margin-bottom: 20px;
    }
    .products-container-any-product {
        margin-bottom: 11px;
        min-height: 115px;
        max-height: 170px;
        padding: 8px;
        width: 100%;
    }
    .menu-container-producs {
        width: 100%;
        padding: 0;
    }
    .search_box_container {
        width: 100%;
        height: 100%;
        position: relative;
        padding-right: 0;
    }
    #user_icon {
        font-size: 20px;
    }
    #user_name {
        margin: 0 3px;
        font-size: 12px;
    }
    .menu-container-tags h6 {
        font-size: 10px;
    }
    .menu-container-list {
        display: none;
    }
    .menu-container-image {
        width: 100%;
        height: 200px;
        background-size: cover;
        margin-top: 40px;
    }
    .product_list_mobile_view {
        display: block;
    }
    .basket_delivery_collection_container {
        display: none;
    }
    .category_sticky_container {
        display: none;
    }
    .basket_sticky {
        display: none;
    }
    .product_list_half_container {
        display: none;
    }
}

@media screen and (max-width: 330px) {}