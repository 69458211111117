    /* MENU STYLES */
    
     :root {
        --primary_color: #ff1e00;
        --primary_light: #ff6237;
        --primary_dark: #c30000;
        --product_title_color: #1d232c;
        --product_description_color: #828585;
        --light_border_color: #eee;
        --dark_border_color: #ccc;
        --height_delivery_collection_button: 55px;
        --height_delivery_collection: 50px;
        --height_delivery_collection_mobile: 40px;
        --height_header_container: 55px;
        --height_signin_button: 45px;
        --border_radius_button: 5px;
        --background_color_main: #f9fafa;
        --background_color_main2: #f0f0f0;
        --content_width: 95%;
        --button_text_color: #fff;
        --button_font_size: 18px;
        --profile_title_color: #1b1b1b;
        --color_white: #fff;
        --footer_subtitle_color: #616161;
        --section_background_color: #f0f0f0;
        --section_background_color_dark: #bebebe;
        --input_normal_height: 45px;
        --drawer_menu_item_color: #757575;
    }
    
    .menu-container-div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        background-color: var(--background_color_main);
        padding-bottom: 150px;
    }
    
    .mobile_header_view {
        display: none;
    }
    
    .menu-container-header {
        width: 100%;
    }
    
    .menu-header-div {
        width: 100%;
        position: fixed;
        z-index: 100;
        background-color: white;
        display: flex;
        justify-content: center;
        height: var(--height_header_container);
        border-bottom: 1px solid var(--light_border_color);
    }
    
    .menu-header-div2 {
        width: var(--content_width);
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .menu-container-logo {
        width: 100%;
        height: 45px;
        display: flex;
        cursor: pointer;
        align-items: center;
    }
    
    .menu-container-logo img {
        width: 37px;
        height: 37px;
        border-radius: 30px;
        object-fit: cover;
    }
    
    .menu-container-logo div {
        margin-left: 10px;
    }
    
    .menu-container-tags h6 {
        color: red;
        margin: 0;
        font-weight: bold;
    }
    
    .user_drop_down_toggle {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    #user_name {
        margin: 0 5px;
        color: black;
    }
    
    #user_icon {
        font-size: 25px;
    }
    
    .search_box_container {
        width: 75%;
        height: 100%;
        position: relative;
        padding-right: 15px;
    }
    
    #main_header_shop_address {
        font-size: 10px;
        color: gray;
    }
    
    .menu-container-cache {
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
    
    .basket_icon_container {
        border: 1px solid #ccc;
        height: 65%;
        display: flex;
        min-width: 85px;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px;
        border-radius: 3px;
        cursor: pointer;
        margin-left: 10px;
    }
    
    .basket_icon_container img {
        height: 80%;
        object-fit: contain;
        margin-right: 10px;
    }
    
    .basket_icon_container h6 {
        margin: 0;
        color: var(--product_title_color);
    }
    
    .lang_dropdown_items {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* #mui_input {
        top: -1;
        font-size: 15px;
    } */
    
    #sign_up_in_login {
        color: var(--primary_color);
        font-weight: bold;
        cursor: pointer;
    }
    
    #sign_up_in_login:hover {
        color: var(--primary_dark);
    }
    
    .forget_password_main_container {
        height: calc(100vh - 50px);
        margin-top: 50px;
        width: 100%;
        display: flex;
    }
    
    .forget_password_main_container2 {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .forget_password_content_container {
        margin-top: 80px;
        padding: 50px;
        border-radius: 5px;
        border: 1px solid var(--dark_border_color);
        background-color: white;
        height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .forget_password_inputs_main_container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    
    .new_password_input_container {
        width: 80%;
        margin: auto;
    }
    
    .new_password_input_container input {
        width: 100%;
        height: var(--input_normal_height);
        border-radius: 5px;
        border: 1px solid var(--light_border_color);
        outline: none;
        padding: 0 10px;
        margin-bottom: 5px;
    }
    
    .new_password_input_container p {
        margin: 0;
        color: red;
        font-size: 14px;
    }
    
    .new_password_input_container div {
        height: 25px;
    }
    
    .forget_password_content_container h6 {
        margin-bottom: 20px;
    }
    
    .new_password_input_container h6 {
        margin: 0;
        margin-bottom: 10px;
    }
    
    .loading_product {
        display: blok;
    }
    
    .loading_all_product {
        display: none;
    }
    
    .forget_password_content_container i {
        color: green;
        font-size: 50px;
        margin-bottom: 10px;
    }
    
    .forget_password_input_title_container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    
    .new_password_input_title_container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 7px;
    }
    
    .new_password_input_title_container h6 {
        margin: 0;
    }
    
    .new_password_input_title_container p {
        margin: 0;
        color: red;
        font-size: 13px;
    }
    
    .forget_password_input_title_container p {
        margin: 0;
        color: red;
    }
    
    .forget_password_content_container h3 {
        font-weight: bold;
        margin-bottom: 20px;
    }
    
    .forget_password_content_container h4 {
        margin-bottom: 30px;
    }
    
    .forget_password_input_container {
        width: 100%;
    }
    
    .forget_password_input_container input {
        height: var(--input_normal_height);
        width: 100%;
        border-radius: 5px;
        border: 1px solid var(--light_border_color);
        outline: none;
        padding: 0 10px;
    }
    
    .forget_password_input_container input:hover {
        border: 1px solid var(--primary_color);
    }
    
    .forget_password_input_container input:focus {
        border: 1px solid var(--primary_color);
    }
    
    .profile-container-secifications {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin-top: 0;
    }
    
    .profile-container-inputs {
        width: 100%;
        margin: 15px 0;
    }
    
    .input_label {
        color: red;
    }
    
    .checkout_input_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10%;
    }
    
    .checkout_input_container h6 {
        text-align: left;
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
        color: var(--product_title_color);
    }
    
    .address_input_container {
        width: 100%;
        border-radius: 5px;
        margin-bottom: 30px;
    }
    
    .address_input_container input {
        width: 100%;
        height: var(--height_delivery_collection);
        margin: 0;
        border: 1px solid var(--light_border_color);
        border-radius: var(--border_radius_button);
        padding: 15px;
        outline: none;
    }
    
    .checkout_input_container input {
        width: 100%;
        height: var(--height_delivery_collection);
        margin: 0;
        border: 1px solid var(--light_border_color);
        border-radius: var(--border_radius_button);
        padding: 15px;
        outline: none;
        color: var(--product_title_color);
    }
    
    .checkout_input_container input:focus {
        border: 1px solid var(--primary_color);
    }
    
    .checkout_input_container input:hover {
        border: 1px solid var(--primary_color);
    }
    
    .checkout_delivery_time_select {
        height: var(--height_delivery_collection);
        /* margin: 0; */
        border: 0.5px solid var(--light_border_color);
        /* border-radius: var(--border_radius_button); */
        /* padding: 15px; */
        text-align: left;
        /* padding: 0; */
    }
    
    .checkout_address_container {
        border: 1px solid var(--light_border_color);
        min-height: var(--height_delivery_collection);
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: var(--border_radius_button);
        margin-bottom: 30px;
        cursor: pointer;
    }
    
    .checkout_error_message_container {
        height: 25px;
    }
    
    .checkout_error_message_container p {
        margin: 0;
        color: red;
    }
    
    .checkout_delivery_time_loader_container {
        border: 1px solid var(--light_border_color);
        border-radius: var(--border_radius_button);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .checkout_address_container:focus {
        border: 1px solid var(--primary_color);
    }
    
    .checkout_address_container:hover {
        border: 1px solid var(--primary_color);
    }
    
    .checkout_delivery_time_container {
        width: 100%;
        margin-bottom: 30px;
        height: 50px;
    }
    
    .checkout_mobile_number_container {
        margin-bottom: 5px;
        width: 100%;
    }
    
    #mobile_number_error_container {
        height: 25px;
        width: 100%;
    }
    
    #mobile_number_error_container p {
        margin: 0;
        color: red;
        font-size: 14px;
    }
    
    #mobile_number_header_container {
        display: flex;
        justify-content: flex-start;
    }
    
    #mobile_number_header_container h6 {
        display: inline;
    }
    
    .checkout_special_note_container {
        width: 100%;
        margin-bottom: 30px;
    }
    
    .checkout_special_note_container textarea {
        width: 100%;
        height: 80px;
        padding: 10px 15px;
        outline: none;
        border-radius: var(--border_radius_button);
        border: 1px solid var(--light_border_color);
        resize: none;
        font-size: 15px;
        color: var(--product_title_color);
    }
    
    .checkout_special_note_container textarea:focus {
        border: 1px solid var(--primary_color);
    }
    
    .checkout_special_note_container textarea:hover {
        border: 1px solid var(--primary_color);
    }
    
    .checkout_address_text_container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 5px 15px;
    }
    
    .checkout_address_text_container p {
        margin: 0;
        font-size: 15px;
        color: var(--product_title_color);
    }
    
    .checkout_arrow_icon_container {
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        margin: auto;
        border-left: 1px solid var(--light_border_color);
        color: var(--dark_border_color);
        font-size: 25px;
    }
    
    .checkout_address_arrow_container {
        width: 8%;
        height: 80%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-left: 2px solid var(--light_border_color);
    }
    
    .checkout_address_arrow_container i {
        font-size: 12px;
        color: var(--dark_border_color);
    }
    
    .profile_input {
        height: 45px;
    }
    
    .profile_input_label {
        font-size: 46px;
        top: -19;
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    
    .login_with_google_main_container {
        width: 100%;
        margin: 15px 0;
    }
    
    .login_with_google_container {
        width: 100%;
        height: 45px;
        margin: 7px 0;
    }
    
    .login_with_google_container button {
        width: 100%;
        height: 100%;
        text-transform: none;
        border: 1px solid var(--dark_border_color);
    }
    
    .login_with_google_icon_title_container {
        display: flex;
        align-items: center;
        width: 70%;
    }
    
    .login_with_google_container p {
        margin: 0;
    }
    
    .login_with_google_icon_title_container img {
        height: 60%;
        width: 15%;
        object-fit: contain;
        margin-right: 20px;
        margin-left: 10px;
    }
    
    .myorders_main_container {
        width: 63%;
        height: 90%;
        overflow-y: auto;
        padding-right: 5px;
        position: relative;
    }
    
    .myorders_main_container::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .myorders_main_container::-webkit-scrollbar:vertical {
        width: 4px;
    }
    
    .myorders_main_container::-webkit-scrollbar:horizontal {
        height: 4px;
    }
    
    .myorders_main_container::-webkit-scrollbar-thumb {
        background-color: var(--primary_color);
        /* border-radius: 7px; */
        /* border: 2px solid #ffffff; */
    }
    
    .myorders_header_container {
        width: 100%;
        height: var(--height_delivery_collection_button);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        background-color: white;
        border: 1px solid var(--light_border_color);
        border-radius: 5px;
        padding: 0 10px;
    }
    
    .myorders_content_container {
        width: 100%;
        height: var(--height_delivery_collection_button);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        background-color: white;
        border: 1px solid var(--light_border_color);
        border-radius: 5px;
        padding: 0px 10px;
    }
    
    .myorders_mobile_item_container {
        display: none;
    }
    
    .myorders_mobile_button_container {
        display: none;
    }
    
    .myorders_item_container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        margin-right: 5px;
    }
    
    .myorders_content_container h6 {
        margin: 0;
    }
    
    .myorders_header_container h6 {
        margin: 0;
        text-align: center;
    }
    
    .myorders_button_container {
        display: flex;
        width: 220px;
        justify-content: space-between;
        height: 70%;
    }
    
    .myorders_button_container button {
        width: 48%;
        height: 100%;
    }
    
    .profile-create-account-button {
        width: 100%;
        height: var(--height_signin_button);
        margin: 15px 0;
        border: 1px solid var(--primary_color);
        background-color: var(--primary_color);
        color: var(--button_text_color);
        border-radius: var(--border_radius_button);
        font-size: var(--button_font_size);
        text-transform: none;
    }
    
    .profile-container {
        width: 100%;
        height: calc(100vh - 50px);
        margin-top: 50px;
        /* background-color: rgb(256, 252, 252); */
        background-color: var(--background_color_main);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: rgb(80, 79, 79);
    }
    
    .profile-create-acount {
        height: 90%;
        width: 35%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        position: relative;
        border: 1px solid var(--dark_border_color);
    }
    
    .profile-sidebar_container {
        height: 90%;
        width: 25%;
        margin-right: 100px;
        background-color: white;
        border-radius: 5px;
        position: relative;
        border: 1px solid rgb(242, 240, 240);
    }
    
    .profile-sidebar_item_container {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5%;
        border: 1px solid rgb(242, 240, 240);
        cursor: pointer;
    }
    
    .profile_side_bar_title_container {
        display: flex;
        align-items: center;
    }
    
    .profile_side_bar_title_container p {
        margin: 0;
    }
    
    .drawer_menu_mobile_view {
        position: fixed;
        z-index: 999;
    }
    
    .drawer_menu_main_list {
        width: 200px;
        font-size: 14px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .drawer_menu_item_main_container {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    
    .drawer_menu_item_main_container i,
    p {
        color: var(--drawer_menu_item_color);
        margin: 0;
    }
    
    .drawer_menu_dvider {
        width: 90%;
        color: var(--dark_border_color);
        margin: 20px 5%;
    }
    
    #profile_user_icon {
        margin-right: 10px;
        font-size: 20px;
    }
    /* .profile-sidebar_item_container:hover {
        background-color: #eeeeee;
    } */
    
    .profile-sidebar_item_container i {
        font-size: 25px;
    }
    
    .profile-sidebar_item_container h5 {
        margin: 0;
        color: var(--profile_title_color);
    }
    
    .profile-create-acount h4 {
        color: var(--profile_title_color);
        text-align: center;
        margin: 0;
        margin-bottom: 15px;
    }
    
    .menu-container-image {
        /* background-image: url(../../public/image/Pic-1.jpg); */
        width: 100%;
        height: 300px;
        /* background-size: */
        background-repeat: no-repeat;
        margin-top: 50px;
        position: relative;
    }
    
    .mobile_time_gap_container {
        display: none;
    }
    
    .discount_notice_container {
        max-width: 80%;
        background-color: rgba(0, 0, 0, 0.6);
        padding: 15px;
        border-radius: 5px;
        margin-top: -50px;
        color: white;
    }
    
    .discount_notice_container h1 {
        margin: 0;
        display: block;
    }
    
    .discount_notice_container h5 {
        display: none;
    }
    
    .menu-main-section {
        background-color: var(--background_color_main);
        width: 100%;
        padding-bottom: 40px;
        position: relative;
        /* border: 1px solid rgb(237, 237, 237); */
    }
    
    .menu-strip-of-main-section {
        width: 98%;
        border: .3px solid rgb(252, 252, 252);
        height: 40px;
        margin: auto;
        background-color: rgba(0, 0, 0, .3);
        color: white;
        display: flex;
        margin-top: -100px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        padding-left: 10px;
    }
    
    .menu-strip-of-main-section h5 {
        /* margin: 8px!important; */
        opacity: 1;
    }
    
    .menu-container-list {
        width: var(--content_width);
        margin: auto;
        margin-top: -60px;
        position: relative;
    }
    
    .product_list_mobile_view {
        display: none;
    }
    
    .menu-container-list::-webkit-scrollbar {
        display: none;
    }
    
    .menu-get-list-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 210px;
        background-color: #f5f5f5;
        padding: 6px;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
        text-align: left;
        margin-right: 20px;
    }
    
    .menu-get-list-details img {
        height: 110px;
        width: 100%;
        object-fit: cover;
        margin-bottom: 8px;
    }
    
    .select {
        z-index: 2;
    }
    
    .menu-container-search-and-dellivery-collection {
        width: var(--content_width);
        height: var(--height_delivery_collection);
        margin: auto;
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
    }
    
    .menu-container-dellivery-collection {
        width: 19%;
        display: flex;
        height: 50px;
        justify-content: space-between;
    }
    /* .menu-container-dellivery-collection div {
    width: 50%;
    text-align: center;
    padding-top: 8px;
    cursor: pointer;
    border: 1px solid rgb(232, 227, 227);
    border-radius: 2px;
    background-color: white;
}

.menu-container-dellivery-collection h6 {
    color: rgb(145, 143, 143);
} */
    
    .menu-container-serachbox {
        width: 100%;
        border: 1px solid rgb(208, 206, 206);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        position: relative;
        border-radius: 5px;
        z-index: 12;
    }
    
    .menu-container-serachbox i {
        margin-left: 10px;
        color: rgb(208, 206, 206);
        margin-right: 10px;
    }
    
    .menu-container-serachbox input {
        width: 97%;
        z-index: 2;
        border: none;
        outline: none;
        height: 100%;
        padding-right: 6px;
        font-size: 14px;
        color: rgb(46, 46, 46);
        border-radius: 5px;
    }
    
    .menu-container-categoriess {
        width: 19%;
        box-shadow: 2px 2px 3px rgb(229, 227, 227), -2px -2px 3px rgb(229, 227, 227);
    }
    
    .menu-container-product-category-tags {
        width: var(--content_width) !important;
        display: flex;
        /* justify-content: space-between; */
        margin-top: 11px !important;
        margin: auto;
    }
    
    .menu-container-producs {
        width: 60%;
        position: relative;
        padding: 0 15px;
    }
    
    .menu-container-seaerchbox {
        position: relative;
    }
    
    .menu-container-seaerchbox input {
        border: none;
        outline: none;
    }
    /* CATEGORY STYLES */
    
    .categories-parent-div {
        border: 1px solid white;
        background-color: white;
        min-height: 400px;
        position: sticky;
        top: 0;
        left: 0;
        width: 19%;
    }
    
    .categories-container-categories-list {
        background-color: white;
        width: 100%;
        height: 35px;
        padding: 0 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    
    .categories-container-categories-list :hover {
        background-color: var(--light_border_color);
        width: 100%;
        height: 35px;
        padding: 0 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    
    .categories-container-categories-list h6 {
        margin: 0;
        font-size: 14px;
    }
    /* PRODUCT STYLES */
    
    .products-container-any-product {
        width: 100%;
        display: flex;
        background-color: white;
        cursor: pointer;
        margin-bottom: 11px;
        border-radius: 8px;
        padding: 8px;
        border: 1px solid var(--light_border_color);
        min-height: 115px;
        max-height: 170px;
    }
    
    .products-container-any-product img {
        width: 100px;
        height: 100%;
        border-radius: 3px;
        object-fit: cover;
    }
    
    .mobile_product_list_category_header_container {
        display: none;
    }
    
    .products-container-texts {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 7px 14px 7px 11px;
        text-align: justify;
        direction: ltr;
        width: 100%;
    }
    /* .products-container-texts h5,
    .menu-get-list-details h5 {
        margin: 0;
        font-weight: bold;
        color: var(--product_title_color);
    } */
    
    .products-container-texts h6,
    .menu-get-list-details h6 {
        margin: 0;
        font-weight: bold;
        color: var(--product_title_color);
    }
    /* .products-container-texts h6 {
        margin: 0;
        color: var(--product_description_color);
        font-weight: bold;
        line-height: 23px;
        max-height: 68px;
        overflow: hidden;
    } */
    
    .products-container-texts p {
        margin: 0;
        color: var(--product_description_color);
        line-height: 20px;
        max-height: 68px;
        overflow: hidden;
        font-size: 14px;
    }
    
    #search_products-container-texts h6 {
        line-height: 22px;
        max-height: 42px;
    }
    
    .menu-get-list-details h6 {
        margin: 0;
        color: var(--product_description_color);
        line-height: 20px;
        max-height: 24px;
        overflow: hidden;
    }
    
    .products-container-texts div {
        margin-bottom: 20px;
    }
    
    #calories_text {
        color: var(--product_description_color);
        font-weight: lighter;
    }
    
    .products-container-image {
        padding: 3px 0px 3px 7px;
    }
    
    .loader_component_container {
        display: flex;
        justify-content: center;
        /* align-items: center; */
        position: absolute;
        z-index: 123456789999;
        /* background-color: rgba(0, 0, 0, 0.75); */
        bottom: 0;
        top: 50px;
        right: 0;
        left: 0;
    }
    /* PRODUCT SUBMENU STYLES */
    
    .create_shop_popup_container {
        background-color: rgba(0, 0, 0, 0.4);
        position: fixed;
        z-index: 12345667;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .maintenance_mode_message_main_container {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 6565656466565656565;
    }
    
    .maintenance_mode_message_content_container {
        background-color: white;
        min-width: 35%;
        max-width: 50%;
        /* min-height: 20%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--dark_border_color);
        border-radius: 5px;
        position: relative;
        padding: 30px 50px;
    }
    
    .maintenance_mode_message_content_container h4 {
        margin: 0;
        display: block;
    }
    
    .maintenance_mode_message_content_container h5 {
        display: none;
    }
    
    .maintenance_mode_message_content_container button {
        margin-top: 30px;
    }
    
    .maintenance_mode_message_content_container i {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 25px;
        color: var(--primary_dark);
        cursor: pointer;
    }
    
    .maintenance_mode_message_content_container i:hover {
        color: var(--primary_color);
    }
    
    #create_shop_popup_content_container_id {
        width: 40%;
    }
    
    .create_shop_popup_content_container {
        background-color: white;
        border: 1px solid rgb(227, 226, 226);
        width: 35%;
        height: 85%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        border-radius: var(--border_radius_button);
    }
    
    .addreses_list_content_container2 {
        background-color: white;
        border: 1px solid rgb(227, 226, 226);
        width: 35%;
        height: 85%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        border-radius: var(--border_radius_button);
    }
    
    .addresses_list_mobile_content_container {
        display: none;
    }
    
    .mobile_basket_main_container {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--color_white);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .change_pass_content_container {
        background-color: #fafafa;
        border: 1px solid rgb(227, 226, 226);
        width: 30%;
        height: 85%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        border-radius: 5px;
        padding: 30px 0;
    }
    
    .product-submenu-popup-container {
        width: 100%;
        overflow-y: auto;
        border: 1x solid red;
        height: 78%;
    }
    
    .product-submenu-popup-container::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .product-submenu-popup-container::-webkit-scrollbar:vertical {
        width: 4px;
    }
    
    .product-submenu-popup-container::-webkit-scrollbar:horizontal {
        height: 4px;
    }
    
    .product-submenu-popup-container::-webkit-scrollbar-thumb {
        background-color: var(--primary_color);
        /* border-radius: 7px; */
        /* border: 2px solid #ffffff; */
    }
    
    .mobile_basket_list_container {
        /* position: absolute;
        top: 65px;
        right: 0;
        left: 0; */
        width: 100%;
        overflow-y: scroll;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 20px 4% 20px 4%;
    }
    
    .mobile_basket_list_container::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .mobile_basket_list_container::-webkit-scrollbar:vertical {
        width: 7px;
    }
    
    .mobile_basket_list_container::-webkit-scrollbar:horizontal {
        height: 7px;
    }
    
    .mobile_basket_list_container::-webkit-scrollbar-thumb {
        background-color: var(--primary_color);
        border-radius: 7px;
        border: 2px solid #ffffff;
    }
    
    .mobile_basket_list_container ::-webkit-scrollbar-track {
        border-radius: 7px;
        background-color: #ffffff;
    }
    
    #basket_container {
        width: 30%;
    }
    
    .mobile_product-submenu-popup-contsiner-footter {
        display: none;
    }
    
    .product-submenu-popup-contsiner-footter {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 0.5px solid var(--dark_border_color);
        margin: auto;
        background-color: var(--section_background_color);
    }
    
    .footer_successful_page {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 0.5px solid var(--dark_border_color);
        margin: auto;
        background-color: var(--section_background_color);
        border-bottom-left-radius: var(--border_radius_button);
        border-bottom-right-radius: var(--border_radius_button);
    }
    
    .mobile_basket_footter_container {
        /* position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1234656788; */
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 0.5px solid var(--dark_border_color);
        padding: 0 4%;
        background-color: var(--color_white);
        z-index: 15656565665665;
    }
    
    .product-submenu-popup-container-image-and-close-icon {
        position: relative;
        width: 100%;
        height: 55%;
    }
    
    .product-submenu-popup-container-image-and-close-icon img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: var(--border_radius_button);
        border-top-right-radius: var(--border_radius_button);
    }
    
    .product-submenu-popup-container-size {
        width: 90%;
        margin: auto;
        display: flex;
        margin-top: 25px;
        margin-bottom: 16px;
        justify-content: space-between;
    }
    
    .product-submenu-popup-container-size h5 {
        margin: 0;
        font-weight: bold;
        color: var(--product_title_color);
    }
    
    .product-submenu-popup-container-size h6 {
        margin: 0;
        color: var(--product_description_color);
        text-align: justify;
    }
    
    .product_submenu_popup_optiones {
        width: 92%;
        margin: auto;
        margin-left: 5%;
        margin-bottom: 10px;
    }
    
    .product_submenu_popup_optiones h6 {
        margin: 0;
        color: var(--product_title_color);
    }
    
    #option_title {
        margin-bottom: 10px;
        margin-top: 5px;
        font-size: 15px;
        color: #b10909e3;
        font-weight: bold;
        /* color: var(--product_description_color); */
    }
    
    .product_submenu_popup_container_details_option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 35px;
        margin-top: 10px;
    }
    
    .product-submenu-popup-contaimer-submenus {
        width: 92%;
        margin: auto;
    }
    
    .product-submenu-popup-hr {
        width: 92%;
        margin: auto;
        margin-left: 5%;
        /* margin-top: 15px; */
        margin-bottom: 16px;
    }
    
    .product-submenu-popup-container-radio-inputs {
        /* display: flex;
flex-direction: column; */
        position: relative;
        line-height: 35px;
        right: 10px;
        padding-left: 5px;
        padding-top: 10px;
    }
    
    .product-submenu-popup-container-close-icon {
        width: 30px;
        height: 30px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 5px;
        top: 4px;
        background-color: white;
        border-radius: 50px;
    }
    
    .product-submenu-popup-container-submenus-informations {
        width: 92%;
        margin: auto;
        margin-left: 5%;
        padding-top: 6px;
    }
    
    .product-submenu-popup-container-titles {
        display: flex;
        /* border: 1px solid palevioletred; */
        /* width: 36%; */
    }
    
    .product-submenu-popup-container-titles h6 {
        color: rgb(80, 79, 79);
        margin: 0;
    }
    
    .product-submenu-popup-container-titles input {
        margin: 0;
    }
    
    .product-submenu-popup-submenu-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .product-submenu-popup-submenu-item h4,
    .product-submenu-popup-submenu-item h3 {
        color: rgb(80, 79, 79);
    }
    
    .product-submenu-popup-contsiner-footter i {
        color: var(--primary_color);
        font-size: 25px;
    }
    
    .product_detail_minus_plus_product {
        width: 90%;
        margin: auto;
        margin-top: 15px;
    }
    
    .product-submenu-popup-contsiner-button {
        width: 90%;
    }
    
    .product-submenu-popup-contsiner-footter button {
        font-size: var(--button_font_size);
        height: var(--height_delivery_collection_mobile);
        width: 100%;
        border: none;
        border-radius: 3px;
        color: white;
        cursor: pointer;
        margin: 20px 0;
        font-weight: bold;
        text-transform: none;
    }
    
    .mobile_basket_footter_container button {
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 3px;
        color: white;
        cursor: pointer;
        font-weight: bold;
        font-size: 15px;
        text-transform: none;
    }
    
    .product-submenu-popup-contsiner-icons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 30%;
        margin: auto;
    }
    
    .product-submenu-popup-contsiner-icons h3 {
        margin: 0;
        font-weight: bold;
        color: var(--product_title_color);
    }
    
    .product-submenu-popup-container-submenu-items {
        display: flex;
        width: 70%;
        justify-content: space-between;
    }
    
    .product-submenu-popup-contsiner-icon {
        padding: 1px 2.5px;
        border-radius: 50px;
        display: flex;
        /* background-color: rgb(167, 192, 167); */
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    
    input[type="checkbox"] {
        opacity: 0;
    }
    
    .Menu-div_countainer-component {
        width: 75%;
        position: relative;
    }
    
    .Menu-div-countainer-CategoryEdit_component {
        height: 100%;
        /* overflow-y: scroll; */
    }
    
    input[type="checkbox"]+label {
        /* display: inline-block; */
        vertical-align: middle;
        content: "";
        width: 14px;
        height: 14px;
        border: .3px solid black;
        position: relative;
        top: 1px;
        bottom: 3px;
        /* left: -24px; */
        background-color: white;
        border-radius: 3px;
        cursor: pointer;
    }
    
    input[type="checkbox"]:checked+label::before {
        background: red;
        content: '\002713';
        font-size: 12px;
        position: relative;
        top: -5px;
        left: -.7px;
        border-radius: 3px;
        border: 1px solid red;
        color: white;
        padding: 0px 1.5px 0px 1.5px;
        cursor: pointer;
    }
    
    .checkmark {
        /* position: absolute;
    top: 2px; */
        /* left: -18px; */
        /* margin-right: -25px; */
    }
    
    .checkmark1 {
        position: absolute;
        top: 3px !important;
        left: -18px;
        margin-right: -10px;
    }
    /* Completely Hiding Radio Button */
    
    Input[type="radio"] {
        Display: None;
    }
    /* Simulate Radiobutton Appearance Using Pseudoselector */
    
    Input[type="radio"]+Label:before {
        Content: "";
        /* Create Custom Radiobutton Appearance */
        Display: Inline-block;
        Width: 18px;
        Height: 18px;
        Padding: 3px;
        /* position: absolute;
    left: 35px;
    margin-top: 3px; */
        /* Background-color Only For Content */
        Background-clip: Content-box;
        Border: 1px Solid black;
        Background-color: white;
        Border-radius: 50%;
        margin-right: 10px;
        margin-left: 3px;
        margin-bottom: -4px;
    }
    
    Input[type="radio"]:checked+Label:before {
        Background-color: red;
        Border: 1px Solid red;
        /* position: absolute;
    left: 35px;
    /* margin-top: 3px; */
    }
    
    *,
    *:before,
    *:after {
        Box-sizing: Border-box;
    }
    
    .sound-signal Label {
        Display: Inline-flex;
        Align-items: Center;
    }
    
    .product-submenu-popup-container-title-and-min-max-select {
        width: 100%;
        display: flex;
    }
    
    .min_max_container {
        display: flex;
        justify-content: space-between;
        width: 45%;
    }
    
    #subitem_style {
        font-size: 16px;
    }
    
    .submenu_title_container {
        width: 25%;
    }
    
    .product-submenu-popup-container-title-and-min-max-select h6 {
        color: var(--product_title_color);
        margin: 0;
    }
    
    #option_required {
        font-size: 14px;
        color: #b10909e3;
        font-weight: bold;
    }
    
    .tap_nav_container {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--top_navbar);
        padding: 0 2%;
    }
    
    .top_nav_profile_container {
        display: flex;
        height: 100%;
        align-items: center;
    }
    
    .top_nav_profile_container span {
        justify-content: space-between;
        display: flex;
        align-items: center;
    }
    
    .create_new_shop_container {
        width: 160px;
        height: 40px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    
    .create_new_shop_container button {
        width: 100%;
        height: 100%;
        background-color: var(--primary_color);
        color: white;
        border-radius: 5px;
        border-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 5%;
    }
    
    .tapNav-dropDown-menu {
        height: 120px;
        border: 1px solid grey;
        border-radius: 3px;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between
    }
    
    .tapNav-dropDown-toggle {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px
    }
    
    .topNav-container-div {
        width: 100px;
        height: 100%;
        background-color: white;
        border: 1px solid white
    }
    
    .loader_mini_component_container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .option_title_price_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .option_title_price_container h6 {
        margin: 0;
    }
    
    .item_number_selector {
        text-align: center;
        width: 29%;
        display: flex;
        justify-content: space-between;
    }
    
    .item_number_selector h5 {
        margin: 0;
    }
    
    .item_number_selector i {
        font-size: 23px;
        color: red;
    }
    
    .item_price_container {
        width: 35%;
        display: flex;
        justify-content: flex-end;
    }
    
    .item_price_container h6 {
        color: var(--product_title_color);
    }
    
    .product_submenu_popup_container_option_crust {
        margin-bottom: 10px;
    }
    
    .product_submenu_popup_container_option_crust h6 {
        margin: 0;
        color: var(--product_title_color);
    }
    
    .product_option_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .basket_product_container {
        display: flex;
        justify-content: space-between;
        margin: 2px 0 5px 0;
    }
    
    .basket_product_subitem_container {
        width: 100%;
    }
    
    .basket_product_title_container {
        display: flex;
        align-items: center;
    }
    
    .mobile_basket_trash_container {
        display: flex;
    }
    
    .basket_product_title_container i,
    .mobile_basket_trash_container i {
        color: var(--primary_color);
        font-size: 20px;
        margin-right: 5px;
        cursor: pointer;
    }
    
    .basket_product_title_container i:active {
        color: var(--primary_light);
    }
    
    #product_number_container {
        background-color: var(--section_background_color_dark);
        border-radius: 200px;
        width: 23px;
        height: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--product_title_color);
        /* margin-right: 5px; */
        margin-top: 1px;
    }
    
    #product_number_container h6 {
        margin: 0;
    }
    
    .basket_product_container p {
        font-weight: bold;
        margin: 0;
        color: var(--product_title_color);
        font-size: 14px;
    }
    
    .basket_product_container h6 {
        margin: 0;
        font-weight: bold;
    }
    
    .basket_sublist_container {
        /* padding: 0 45px 0 45px; */
    }
    
    .basket_sublist_container div {
        display: flex;
        justify-content: space-between;
    }
    
    .basket_sublist_container p {
        margin: 0;
        color: var(--product_title_color);
    }
    
    .basket_sublist_container h6 {
        color: var(--product_description_color);
    }
    
    .basket_empty_message_container {
        display: flex;
        height: 430px;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-image: url(../assets/images/bg_empty_basket2.jpg);
        background-size: cover;
        border-radius: 10px;
    }
    
    .basket_empty_message_container h4 {
        margin: 0;
        font-weight: bold;
        margin-top: 15px;
    }
    
    .basket_empty_message_container img {
        border-radius: 10px;
    }
    
    .basket_footer_container {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        margin-top: 25px;
    }
    
    .mobile_basket_footer_content_container {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }
    
    .basket_footer_container div,
    .mobile_basket_footer_content_container div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }
    
    .basket_footer_container h6 {
        margin: 0;
    }
    
    .basket_header_container {
        display: none;
    }
    
    #address_header_title {
        display: block;
    }
    
    .mobile_basket_close_button {
        /* background-color: pink; */
        /* height: 100%;
        width: 20%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: var(--primary_color);
        font-size: 23px; */
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--primary_color);
        font-size: 23px;
    }
    
    .basket_header_logo_container {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 200px;
    }
    
    .basket_header_logo_container img {
        height: 90%;
        object-fit: contain;
    }
    
    .basket_delivery_collection_container {
        display: flex;
        background-color: var(--light_border_color);
        width: 70%;
        justify-content: space-between;
        height: 100%;
        border-radius: 100px;
        align-items: center;
        padding: 1px;
        border: 1px solid var(--dark_border_color);
    }
    
    .basket_delivery_collection_container img {
        width: 25px;
        /* object-fit: cover; */
    }
    
    .mobile_basket_delivery_collection_container {
        display: flex;
        background-color: var(--light_border_color);
        width: 80%;
        justify-content: space-between;
        height: var(--height_signin_button);
        border-radius: 100px;
        align-items: center;
        padding: 1px;
        border: 1px solid var(--dark_border_color);
    }
    
    .mobile_basket_delivery_collection_container i {
        margin: 0;
    }
    
    .mobile_basket_delivery_collection_container img {
        margin-right: 5px;
    }
    
    .mobile_basket_delivery_collection_container h6 {
        margin: 0;
    }
    
    .basket_delivery_collection_container div {
        display: flex;
        flex-direction: column;
        width: 48%;
        height: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        cursor: pointer;
    }
    
    .mobile_basket_delivery_collection_container div {
        display: flex;
        /* flex-direction: column; */
        width: 48%;
        height: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        cursor: pointer;
    }
    
    .basket_delivery_collection_container h5 {
        margin: 0;
        color: var(--product_title_color);
    }
    
    .basket_delivery_collection_container h6 {
        margin: 0;
    }
    
    .basket_delivery_collection_container p {
        margin: 0;
        font-size: 13px;
    }
    
    .popular_price_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .popular_price_container div {
        background-color: #fafafa;
        padding: 3px;
        border-radius: 2px;
    }
    
    .popular_price_container p {
        margin: 0;
        color: var(--product_title_color);
    }
    
    .slide_button_container_right {
        background-color: #fff;
        width: 50px;
        height: 50px;
        border-radius: 3px;
        position: absolute;
        top: 122px;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    
    .slide_button_container_right:active,
    .slide_button_container_left:active {
        background-color: var(--light_border_color);
    }
    
    .slide_button_container_left {
        background-color: #fff;
        width: 50px;
        height: 50px;
        border-radius: 3px;
        position: absolute;
        top: 122px;
        left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    
    .slide_button_container_right i,
    .slide_button_container_left i {
        color: var(--primary_color);
    }
    
    .carousel.carousel-slider .control-arrow {
        color: red;
    }
    
    .product_list_container {
        width: 100%;
        display: flex;
    }
    
    .product_list_half_container {
        width: 50%;
    }
    
    .remember_me_forgot_pass_container {
        width: 100%;
        margin: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .remember_me_forgot_pass_container h6 {
        margin: 0;
        cursor: pointer;
    }
    
    .profile_main_container {
        background-color: var(--background_color_main);
        width: 100%;
        height: 100vh;
        padding-top: 100px;
        padding-left: 50px;
        display: flex;
        justify-content: flex-start;
    }
    
    .buttons_container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 15px 0;
    }
    
    .buttons_container button {
        width: 48%;
        font-size: var(--button_font_size);
    }
    
    #change_password {
        background-color: #84ffff;
        color: var(--product_title_color);
        border: 1px solid aqua;
        text-transform: none;
    }
    
    #add_address {
        background-color: #2196f3;
        border: 1px solid #3d5afe;
        color: var(--product_title_color);
        text-transform: none;
    }
    
    .change_password_button_container {
        width: 100%;
        display: flex;
        height: var(--height_delivery_collection_button);
    }
    
    .change_password_button_container button {
        width: 90%;
        margin: auto;
        height: 100%;
        background-color: var(--primary_color);
        border: 1px solid var(--primary_color);
        border-radius: var(--border_radius_button);
        color: white;
        font-size: var(--button_font_size);
    }
    
    .change_password_inputs_container {
        width: 90%;
        text-align: center;
        margin: auto;
    }
    
    .change_password_inputs_container h6 {
        margin: 0;
        margin-bottom: 50px;
    }
    
    .change_password_textfield_container {
        width: 100%;
        margin-bottom: 20px;
    }
    
    .add_address_button_container {
        width: 100%;
        padding: 0 4%;
        height: var(--height_delivery_collection);
        margin-bottom: 3%;
    }
    
    .add_address_button_container button {
        width: 100%;
        height: 100%;
        border-radius: var(--border_radius_button);
        background-color: var(--primary_color);
        color: var(--button_text_color);
        border-color: var(--primary_color);
        font-size: var(--button_font_size);
        text-transform: none;
    }
    
    .go_to_payment_button_container {
        width: 100%;
        padding: 0 5%;
        height: var(--height_delivery_collection_button);
        margin-bottom: 5%;
        display: block;
    }
    
    .go_to_payment_button_container_mobile {
        display: none;
    }
    
    .go_to_payment_button_container button {
        width: 100%;
        height: 100%;
        border-radius: var(--border_radius_button);
        background-color: var(--primary_color);
        color: var(--button_text_color);
        border-color: var(--primary_color);
        text-transform: none;
        font-size: var(--button_font_size);
    }
    
    .mobile_category {
        display: none;
    }
    
    .mobile_add_address_button_container {
        display: none;
    }
    
    .addresses_inputs_container {
        width: 100%;
        padding: 5%;
    }
    
    .add_address_title_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }
    
    .add_address_title_container i {
        cursor: pointer;
    }
    
    .add_address_title_container h5 {
        margin: 0;
    }
    
    .addresses_list_main_container {
        width: 100%;
        overflow-y: scroll;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .addresses_list_main_container::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .addresses_list_main_container::-webkit-scrollbar:vertical {
        width: 4px;
    }
    
    .addresses_list_main_container::-webkit-scrollbar:horizontal {
        height: 4px;
    }
    
    .addresses_list_main_container::-webkit-scrollbar-thumb {
        background-color: var(--primary_color);
        border-radius: 4px;
        /* border: 2px solid #ffffff; */
    }
    
    .addresses_list_main_container h5 {
        margin: 0;
        text-align: center;
        margin-bottom: 25px;
    }
    
    .address_list_item_container {
        display: flex;
        width: 98%;
        border: 1px solid var(--light_border_color);
        padding: 2%;
        margin-bottom: 15px;
        border-radius: var(--border_radius_button);
    }
    
    .address_list_address_container {
        width: 92%;
        display: flex;
        align-items: center;
    }
    
    .address_list_address_container p {
        margin: 0;
        font-size: 15px;
    }
    
    .addresses_list_radio_button_label {
        /* font-size: 45px;
        color: #2196f3; */
    }
    
    .address_list_edit_container {
        min-height: 100%;
        width: 8%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .address_list_edit_container i {
        font-size: 20px;
        color: var(--primary_color);
        cursor: pointer;
    }
    
    .address_list_button_container {
        width: 96%;
        align-self: flex-start;
        display: flex;
        align-items: center;
        padding-top: 25px;
    }
    
    .mobile_address_list_button_container {
        display: none;
    }
    
    .order_details_main_container {
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 36656566;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        /* padding-top: 30px; */
        /* margin-top: 30px; */
        align-items: center;
    }
    
    .order_details_container {
        height: 90%;
        width: 35%;
        background-color: var(--section_background_color);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: var(--border_radius_button);
    }
    
    #add_address_button {
        display: inline-flex;
        cursor: pointer;
    }
    
    .address_list_button_container i {
        font-size: 25px;
        color: var(--primary_color);
        margin-right: 10px;
    }
    
    .address_list_button_container h5 {
        margin: 0;
    }
    
    .categories_title_container {
        height: 47px;
        padding: 11px 0px 0px 8px;
    }
    
    .categories_title_container h6 {
        margin: 0;
        font-weight: bold;
    }
    
    .verification_code_container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .verification_code_container input {
        width: 70%;
        height: var(--height_delivery_collection);
        margin: 0;
        border: 1px solid var(--light_border_color);
        border-radius: var(--border_radius_button);
        padding: 15px;
        outline: none;
        margin-bottom: 30px;
        text-align: center;
    }
    
    .verification_code_container input:hover {
        border-color: var(--primary_color);
    }
    
    .verification_code_container input:focus {
        border-color: var(--primary_color);
    }
    
    .verification_code_container button {
        width: 70%;
        height: var(--height_delivery_collection);
        border-radius: var(--border_radius_button);
        color: var(--button_text_color);
        border-color: var(--primary_color);
        font-size: var(--button_font_size);
        outline: none;
    }
    
    .verification_code_header_container {
        margin-bottom: 5px;
    }
    
    .verification_code_header_container h6 {
        text-align: center;
    }
    
    .verification_code_header_container h5 {
        text-align: center;
    }
    
    .review_container {
        height: calc(100vh - 50px);
        margin-top: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* background-color: var(--background_color_main); */
        background-color: #fff;
    }
    
    .six_step_mobile_container {
        display: none;
    }
    
    .review_content_container {
        height: 85%;
        width: 60%;
        display: flex;
        justify-content: space-between;
    }
    
    .successful_content_container {
        height: 85%;
        width: 40%;
        display: flex;
        justify-content: center;
    }
    
    .badge {
        animation: color-change 1s infinite;
    }
    
    @keyframes color-change {
        0% {
            background-color: white;
        }
        30% {
            background-color: #fdc89a
        }
        50% {
            background-color: #f6b780
        }
        80% {
            background-color: #fab070
        }
        100% {
            background-color: var(--main_color)
        }
    }
    
    .review_payment_method {
        max-height: 100%;
        width: 35%;
        border-radius: 10px;
        background-color: var(--background_color_main2);
        border: 1px solid var(--dark_border_color);
        padding: 20px 0;
    }
    
    .review_basket {
        height: 100%;
        width: 60%;
        border: 1px solid var(--dark_border_color);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        background-color: var(--background_color_main2);
    }
    
    .successfull_basket {
        height: 100%;
        width: 66%;
        border: 1px solid var(--dark_border_color);
        border-radius: var(--border_radius_button);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .successfull_result_message_container {
        width: 60%;
        padding: 0 3%;
    }
    
    .successfull_message_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 30px;
        border: 1px solid var(--dark_border_color);
        border-radius: var(--border_radius_button);
        margin-bottom: 20px;
        text-align: center;
    }
    
    .customer_info_container {
        display: flex;
        flex-direction: column;
        /* justify-content: center;
        align-items: center; */
        width: 100%;
        padding: 30px;
        border: 1px solid var(--dark_border_color);
        border-radius: var(--border_radius_button);
    }
    
    .customer_info_container i {
        margin-right: 10px;
        font-size: 20px;
    }
    
    .customer_info_container h5,
    h6 {
        margin: 0;
    }
    
    .customer_info_container h5 {
        margin-bottom: 10px;
    }
    
    .successfull_message_container i {
        color: green;
        font-size: 80px;
        margin-bottom: 20px;
    }
    
    .review_checkbox_container {
        margin: auto;
        margin-bottom: 20px;
        width: 90%;
        padding: 3px 7px;
        border: 1px solid var(--dark_border_color);
        border-radius: var(--border_radius_button);
        background-color: var(--color_white);
    }
    
    .review_basket_header_container {
        /* padding: 10px; */
        background-color: var(--primary_color);
        border-top-left-radius: var(--border_radius_button);
        border-top-right-radius: var(--border_radius_button);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color_white);
        height: var(--height_delivery_collection_mobile);
    }
    
    .successful_basket_header_container {
        background-color: var(--section_background_color_dark);
        border-top-left-radius: var(--border_radius_button);
        border-top-right-radius: var(--border_radius_button);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color_white);
        height: var(--height_delivery_collection_mobile);
        width: 100%;
    }
    
    .successful_basket_footer_container {
        background-color: green;
        border-bottom-left-radius: var(--border_radius_button);
        border-bottom-right-radius: var(--border_radius_button);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color_white);
        height: var(--height_delivery_collection_mobile);
        width: 100%;
    }
    
    .successful_basket_footer_container h6 {
        margin: 0;
    }
    
    .review_basket_header_container h6 {
        margin: 0;
    }
    
    .successful_basket_header_container h6 {
        margin: 0;
        color: var(--product_title_color);
    }
    
    .review_basket_list_container {
        display: flex;
        flex: 1;
        padding: 20px 12%;
        overflow-y: auto;
        flex-direction: column;
        width: 100%;
        margin: auto;
    }
    
    .review_basket_list_container::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .mobile_pay_method_container {
        display: none;
    }
    
    .review_basket_list_container::-webkit-scrollbar:vertical {
        width: 4px;
    }
    
    .review_basket_list_container::-webkit-scrollbar:horizontal {
        height: 4px;
    }
    
    .review_basket_list_container::-webkit-scrollbar-thumb {
        background-color: var(--primary_color);
        /* border-radius: 7px; */
        /* border: 2px solid #ffffff; */
    }
    
    .review_footer_container {
        display: none;
    }
    
    .successfull_basket_list_container {
        flex: 1;
        padding: 20px 5%;
        overflow-y: auto;
        background-color: var(--section_background_color);
        position: relative;
    }
    
    .six_step_container {
        width: 20%;
        height: 85%;
        background-color: var(--section_background_color);
        margin-right: 40px;
        padding: 30px;
        border-radius: var(--border_radius_button);
        display: block;
    }
    
    .review_subtotal {
        display: flex;
        justify-content: space-between;
    }
    
    #main_header_menu_text_container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
    }
    
    #user_container {
        min-width: 85px;
        height: 70%;
        border: 1px solid #ccc;
        margin-left: 40px;
        border-radius: 3px;
    }
    
    .drawer {
        display: none;
    }
    
    #main_header_menu_text_container h6 {
        margin: 0;
        color: var(--primary_color);
        cursor: pointer;
    }
    
    #main_header_menu_text_container h6:hover {
        color: var(--primary_light);
    }
    
    .footer_container {
        width: 100%;
        height: 240px;
        border-top: 1px solid var(--dark_border_color);
        background-color: var(--background_color_main);
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        justify-content: center;
        align-items: center;
    }
    
    .footer_container_mobile {
        display: none;
    }
    
    .footer_content {
        width: var(--content_width);
        /* height: 200px; */
        display: flex;
        justify-content: space-between;
    }
    
    #footer_first_section {
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    
    #footer_first_section h3 {
        margin: 0;
        margin-bottom: 30px;
    }
    
    #footer_first_section h6 {
        color: var(--footer_subtitle_color);
        cursor: pointer;
    }
    
    #footer_first_section h6:hover {
        color: var(--product_title_color);
    }
    
    #footer_second_section {
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    
    #footer_second_section h3 {
        margin: 0;
        margin-bottom: 30px;
    }
    
    #footer_second_section h6 {
        color: var(--footer_subtitle_color);
        cursor: pointer;
    }
    
    #footer_second_section h6:hover {
        color: var(--product_title_color);
    }
    
    #footer_third_section {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    
    #footer_fourth_section {
        width: 25%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    
    #footer_fourth_section h3 {
        margin: 0;
        margin-bottom: 30px;
    }
    
    #footer_fourth_section i {
        font-size: 30px;
        cursor: pointer;
    }
    
    #footer_facebook {
        margin-right: 15px;
    }
    
    #footer_third_section h3 {
        margin: 0;
        margin-bottom: 30px;
    }
    
    #footer_third_section img {
        width: 35%;
        object-fit: cover;
        cursor: pointer;
        margin-left: 6px;
        margin-right: 6px;
    }
    
    #footer_third_section div {
        display: flex;
    }
    
    .search_container {
        max-height: 400px;
        width: 100%;
        overflow-y: scroll;
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 12345665656555657;
        background-color: var(--light_border_color);
        margin-top: 10px;
        border: 1px solid var(--dark_border_color);
        justify-content: space-between;
    }
    
    .search_container hr {
        margin: 0;
        width: 92%;
        height: 2px;
        margin: auto;
        background-color: var(--dark_border_color);
        border: none;
    }
    
    .search_result_title_container {
        width: 100%;
        padding: 10px 4%;
        margin-top: 5px;
    }
    
    .search_result_title_container h4 {
        margin: 0;
        font-weight: bold;
    }
    
    .search_vert {
        /* margin: 0;
        background-color: #84ffff; */
        width: 5px;
        margin-top: -5px;
        color: #660000;
        background-color: #9b0000;
        border: 1px solid skyblue;
        transform: rotate(90deg);
        /* -ms-transform: rotate(90deg); */
        /* IE 9 */
        /* -webkit-transform: rotate(90deg); */
        /* Safari and Chrome */
    }
    
    .for_test_container {
        width: 100%;
        height: 100vh;
    }
    
    .search_product_main_item_container {
        background-color: white;
        padding: 0 4%;
        width: 100%;
    }
    
    .search_product_container {
        width: 100%;
        display: flex;
        background-color: white;
        cursor: pointer;
        padding: 10px 0;
        min-height: 100px;
        max-height: 150px;
    }
    
    .search_product_container:hover {
        background-color: #eee;
        cursor: pointer;
    }
    
    .search_product_container img {
        width: 110px;
        height: 100%;
        border-radius: 3px;
        object-fit: cover;
    }
    
    .count_down_resend_container {
        width: 70%;
        display: flex;
        /* justify-content: space-between; */
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    
    .count_down_resend_container h6 {
        margin: 0;
        color: var(--primary_color);
    }
    
    #resend_otp_code {
        margin-left: 10px;
        display: inline-block;
        cursor: pointer;
    }
    
    .count_down_resend_container button {
        width: 10%;
    }
    
    #count_down_container {
        /* background-color: pink; */
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    #count_down_container h5 {
        margin: 0;
    }
    
    #count_down_decade {
        background-color: aqua;
        margin-right: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 30px;
        border-radius: 2px;
        /* padding: 0 7px; */
    }
    
    #count_down_one {
        background-color: aqua;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        border-radius: 2px;
        /* padding: 0 7px; */
    }
    
    .footer_copy_right_container {
        height: 70px;
        width: 100%;
        background-color: var(--primary_color);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .footer_copy_right_container h6 {
        margin: 0;
        color: var(--color_white);
        display: block;
    }
    
    .footer_copy_right_container p {
        display: block;
        margin: 0;
        color: var(--color_white);
        font-size: 14px;
    }
    
    .basket_sticky {
        position: sticky;
        top: 60px;
        right: 0;
        border-radius: 10px;
        width: 25%;
    }
    
    .basket_sticky_content {
        border: 1px solid var(--light_border_color);
        border-radius: 10px;
        background-color: white;
        position: sticky;
        top: 60px;
        right: 0;
    }
    
    .basket_sticky_list-content {
        max-height: calc(100vh - 305px);
        min-height: 200px;
        overflow-y: auto;
        padding: 5%;
        scrollbar-color: var(--primary_color);
    }
    
    .basket_sticky_list-content::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .basket_sticky_list-content::-webkit-scrollbar:vertical {
        width: 4px;
    }
    
    .basket_sticky_list-content::-webkit-scrollbar:horizontal {
        height: 4px;
    }
    
    .basket_sticky_list-content::-webkit-scrollbar-thumb {
        background-color: var(--dark_border_color);
        /* border-radius: 7px; */
        /* border: 2px solid #ffffff; */
    }
    /* .basket_sticky_list-content ::-webkit-scrollbar {
        width: 12px;
        background-color: #d62828;
    }
    
    .basket_sticky_list-content::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #8a1b1b;
    }
    
    .basket_sticky_list-content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #650505;
    } */
    
    .category_sticky_container {
        position: sticky;
        top: 60px;
        left: 0;
        width: 15%;
        max-height: calc(100vh - 75px);
        overflow-y: auto;
        background-color: var(--color_white);
        border: 0.5px solid var(--light_border_color);
        border-radius: 10px;
    }
    
    .category_sticky_container::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .category_sticky_container::-webkit-scrollbar:vertical {
        width: 4px;
    }
    
    .category_sticky_container::-webkit-scrollbar:horizontal {
        height: 4px;
    }
    
    .category_sticky_container::-webkit-scrollbar-thumb {
        background-color: var(--dark_border_color);
        /* border-radius: 7px; */
        /* border: 2px solid #ffffff; */
    }
    /* square buttons */
    
    .rec.rec-arrow {
        border-radius: 0;
    }
    /* round buttons on hover */
    
    .rec.rec-arrow:hover {
        border-radius: 50%;
    }
    /* hide disabled buttons */
    
    .rec.rec-arrow:disabled {
        visibility: hidden;
    }
    /* disable default outline on focused items */
    /* add custom outline on focused items */
    
    .rec-carousel-item:focus {
        outline: none;
        box-shadow: inset 0 0 1px 1px lightgrey;
    }
    
    .close_in_mobile_container {
        display: none;
    }
    
    .button_view_basket_mobile {
        display: none;
    }
    
    #mobile_view_basket_item_count {
        display: none;
    }